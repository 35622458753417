<template>




  <div class="md"> 
    <div class="bk" @click="close"></div>
    <div class="md__content"> 

      <div class="md__close"
        @click="close"
        >
        <i class="icon-close-thin"></i>
      </div>

      <div class="md__body pc" ref="modalBody"> 
        <DiarywideViewPage
          v-if=" useModalsDiary().value"
          :modal-id="useModalsDiary().value.id"
          />
      </div>
    </div>
  </div> 

</template>

<script setup lang="ts">

const id = ref(null);
const link = ref(null);
const data = ref(null);
const oldUrl = ref(null);
const modalBody = ref(null);

const router = useRouter();

const close = (closeWithPath: boolean = true) => {
  if (closeWithPath && oldUrl.value) {
    useRouteHistory().push(oldUrl.value);
  }

  useModalsDiaryShow().value = false;
}

onMounted(() => {

  if (!useModalsDiary().value) {
    return;
  }

  id.value = useModalsDiary().value.id;
  link.value = useModalsDiary().value.link;
  data.value = useModalsDiary().value.data;

  // set browser url
  if (link.value) {
    oldUrl.value = window.location.href;
    useRouteHistory().push(link.value);
    gaView();
  }

  useModalsDiaryModalRef().value = modalBody.value;

})

const gaView = () => {  
  const { gtag } = useGtag();  
  gtag('event', 'page_view', {
    page_title: document?.title,
    page_location: window?.location.href,
    page_path: window?.location?.pathname,
  })
}

onUnmounted(() => {
  // reset browser url
  

  useModalsDiaryModalRef().value = null;
})


watch(router.currentRoute, (newCurrentRoute, oldCurrentRoute) => {
  close(false);
}, {deep: true})

</script>


<style scoped>
 
.md{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 101;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.md__content{
  position: relative;
  width: 86%;
  height: 100vh;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--un-background-color);
  margin: 0 auto;
  /* border-radius: 1rem 1rem 0 0; */
}

.md__close{
  position: absolute;
  top: 0;
  padding: 1rem;
  cursor: pointer;
  color: white;
  font-size: 1.6rem;
  right: -3.9rem;
  aspect-ratio: 1 / 1;
}

.md__body{  
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overscroll-behavior: contain;
  position: relative;
}

/* .md__body::before{
  content: "";
  display: block;
  height: 2rem;
  background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0));
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
} */

.bk{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
}


.pc{
  container: pc / inline-size;
}

</style>
