<template>            

  <div class="popup">

    <div v-if="loading" class="bloading">
      <div class="gloading"></div>
    </div>


    <template       
      v-for="notice in notices"      
      >

      <UserpopupnoticesDefault        
        :notification-id="notice.id"
        :link="notice.link"
        :action="notice.action"
        :type="notice.type"
        :message-text="notice.message_text"
        :last_update="notice.last_update"
        :items_user="notice.items_user"
        :is-new="notice.is_new"
        />

    </template>

    <NuxtLink 
      to="/notifications"
      class="more">
      {{ $t('main_menu_all_notifications') }} 
      <i class="icon-angle-right"></i>
    </NuxtLink>
    
  </div>

  
</template> 


<script setup lang="ts">

interface PageProps {
  showed: boolean;    
}

const props = withDefaults(defineProps<PageProps>(), {
  showed: false,      
})


const { $api } = useNuxtApp();


const emits = defineEmits(['update:showed']);

const notices = ref([]);
const loading = ref(false);
const start = ref(0);

const toggleLoader = (state) => {
  loading.value = state;
}

const loadUserNotices = async function() {        
  
  console.log('load notices user');
  
  toggleLoader(true);
  notices.value = await $api.getNotifications(useAuth().getId(), 0, 5, 1)
  


  useUserUpdatesNotifications().value = null;


  toggleLoader(false);
}

const hidePopup = () => {
  emits('update:showed', false);
}


 

onMounted(() => {
  loadUserNotices();
  // setIntervalCheck();
})

onBeforeUnmount(() => {
  notices.value = [];
  // clearInterval();
})
   
</script>
  

<style scoped>
  
.popup{  
  position: absolute;
  right: 0;
  top: 50px;
  background-color: var(--gd-navbar-back-color);
  z-index: 10;
  padding: 0;
  border-radius: 3px;
  box-shadow: 0px 2px 3px 0px rgb(34 36 38 / 15%);
  border: 1px solid rgba(34, 36, 38, 0.15);
  min-width: 360px;
  max-height: 400px;
  overflow-y: auto;
  height: fit-content;

  cursor: default;
  
}
.popup.showed{
  display: flex;
  flex-direction: column;
}
.popup .row{
  padding: 0.58571429rem 1.14285714rem;
  display: flex;
  align-content: center;
}

.popup .row.create{
  font-weight: bold;
  cursor: pointer;
}
.popup .row .name{
  cursor: pointer; 
  width: 100%;
}
.popup .row .av{
  width: 20px;
  height: 20px;
  border-radius: 5px;
  flex-shrink: 0;
}
.popup .row i{
  
}

.popup .row:not(.txt):hover{
  background-color: var(  --gd-navbar-back-hover-color);
}


.more{
  padding: 10px;
  display: flex;
  align-items: center;
}

.bloading{
  position: relative;
  min-height: 45px;
}
@media (max-width: 768px) {
  
  .grdr{
    display: none;
  }
}

</style>
