<template>



  <div 
    class="over-player-pc"
  >
    <div            
      class="over-player"    
      >        
        <GeneralShortsPageWithPlayer
          v-if="useModalsShortModalList().value"
          :list="useModalsShortModalList().value.slice(useModalsShortModalListIndex().value)"
          :close-type="'close'"
          @close="close"
          @loadMore="loadMore"
        />
    </div>
  </div> 
</template>

<script setup lang="ts">

const id = ref(null);
const link = ref(null);
const data = ref(null);
const oldUrl = ref(null);
const modalBody = ref(null);

const router = useRouter();

const close = (closeWithPath: boolean = true) => {
  if (closeWithPath && oldUrl.value) {
    useRouteHistory().push(oldUrl.value);
  }

  useModalsShortShow().value = false;
  useModalsShortHidePlayerFunc().value();
}

const loadMore = () => {
  useModalsShortLoadMoreFunc().value();
}

onMounted(() => {

  if (!useModalsShort().value) {
    return;
  }

  id.value = useModalsShort().value.id;
  link.value = useModalsShort().value.link;
  data.value = useModalsShort().value.data;

  // set browser url
  if (link.value) {
    oldUrl.value = window.location.href;
    useRouteHistory().push(link.value);
    

    gaView();
  }

  useModalsShortModalRef().value = modalBody.value;

})

const gaView = () => {  
  const { gtag } = useGtag();  
  gtag('event', 'page_view', {
    page_title: document?.title,
    page_location: window?.location.href,
    page_path: window?.location?.pathname,
  })
}

onUnmounted(() => {
  // reset browser url
  useModalsShortModalRef().value = null;
})


watch(router.currentRoute, (newCurrentRoute, oldCurrentRoute) => {
  close(false);
}, {deep: true})

</script>


<style scoped>
 


.over-player-pc{
  container: pc / inline-size;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11100;
  height: 100dvh;
  width: 100%;
}
.over-player{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0 0 0 / 84%);
  z-index: 11100;
  height: 100dvh;
  width: 100%;
  padding-top: 40px;    
}
@container pc (max-width: 500px) { 
  .over-player{
    padding-top: 0;
  }
}

</style>
