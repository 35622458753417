<template>
  <div class="scrolltop_observer" ref="scrollObserver"></div>

  <ClientOnly>
    <div 
      ref="elWrapper" 
      :style="{ 'margin-bottom': usePageBottomMargin().value + 'px' }"
      :class="{'wrapper': true, 'visible': !isActive}" @click="top">
      <div class="button"></div>
    </div>
  </ClientOnly>
 
</template>

<script setup lang="ts">

const { $observer } = useNuxtApp();
const scrollObserver = ref(null);
const isActive = ref(false);
const elWrapper = ref(null);
const bottomMargin = usePageBottomMargin(); 

const top = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

 
onMounted(() => {

  $observer(
    scrollObserver.value,
    () => {
      isActive.value = true;
    },
    () => {
      isActive.value = false;
    }
  );
});

 


</script>

<style scoped>

.scrolltop_observer{
  position: absolute;
  top: 400px;
  z-index: 11111111111;
}

.wrapper {
    display: none;
    position: fixed;
    z-index: 99998;
    cursor: pointer;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    right: 10px;
    bottom: var(--bottom-margin, 61px);
}
.bottom:hover{
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -ms-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    bottom: calc(var(--bottom-margin, 61px) + 10px);
}
.wrapper.visible {
    display: block
} 

.button {
  width: 40px;
  height: 40px;
  background-color: var(--un-background-color-gray-dark);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  /* box-shadow: 0px 0px 10px rgb(0 0 0 / 21%); */
}

.button, 
.button::before {
  display: block;
  position: relative;
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  -ms-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s
}

.button::before {
    content: "";
    left: 16px;
    top: 17px;
    width: 9px;
    height: 9px;
    border-right: 4px solid var(--un-text-color);
    border-top: 4px solid var(--un-text-color);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}



@media (min-width: 768px) {
  .wrapper {
    margin-bottom: 0!important;
  }
}
 
</style>
